
export const navigation = [
	{name: 'home', href: '/'},
	{name: 'meetings', href: '/meetings?mode=me'},
	{name: 'events', href: '/events'},
	{name: 'links', href: '/links'}
]

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}
