import * as React from "react"
import PropTypes from "prop-types"
import _ from "lodash/string"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Disclosure } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { navigation } from './layout/util'
import moment from 'moment'

import '../styles/global.css'
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import "@fontsource/roboto/900.css"

const { Fragment } = React

// const PrivacyListItem = () => (
//   <li key="privacy" className="pb-1">
//     <Link
//       to="/privacy-policy"
//       activeClassName="underline"
//     >
//       Privacy
//     </Link>
//   </li>
// )

const Layout = ({ children }) => {
  return (
    <>
      <div>
        <Disclosure as="nav" className="bg-royale">
          {({ open }) => (
            <>
              <div className="container mx-auto px-4">
                <div className="flex items-center justify-between h-16">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <small className="h-8 w-8 text-lg text-white font-bold">District 28 AA</small>
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map(({ name, href }, itemIdx) =>
                          (
                            <Link
                              key={name}
                              to={href}
                              className="text-white hover:bg-blue-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                              activeClassName="border border-gray-900 bg-blue-700 text-white px-3 py-2 rounded-md text-sm font-medium"
                            >
                              {_.capitalize(name)}
                            </Link>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block text-white">
                    HOTLINE: <a className="underline font-semibold" href="tel:9892559036">989-255-9036</a>
                  </div>
                  <div className="hidden md:hidden">
                    <div className="ml-4 flex items-center md:ml-6">
                      <button className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  {navigation.map(({ name, href }, itemIdx) =>
                    (
                      <Link
                        key={name}
                        to={href}
                        className="text-white hover:bg-blue-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                        activeClassName="border border-gray-900 bg-blue-700 text-white block px-3 py-2 rounded-md text-base font-medium"
                      >
                        {_.capitalize(name)}
                      </Link>
                    )
                  )}
                </div>
                <div className="text-white text-center py-4">
                  HOTLINE: <a className="underline hover:font-semibold" href="tel:9892559036">989-255-9036</a>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        { children }
        <footer className="bg-blue-600 text-white">
          <div className="container flex flex-wrap mx-auto px-4 py-20">
            <div className="w-full lg:w-1/2 flex flex-col justify-center md:justify-start pb-10 lg:pb-0">
              <h3 className="text-xl font-bold pb-4 text-center lg:text-left">Navigation</h3>
              <ul className="text-center lg:text-left">
                {navigation.map(({ name, href }, itemIdx) =>
                  (
                    <li key={itemIdx} className="pb-1">
                      <Link
                        to={href}
                        activeClassName="underline"
                      >
                        {_.capitalize(name)}
                      </Link>
                    </li>
                  )
                )}
              </ul>
            </div>
            <div className="w-full lg:w-1/2 flex justify-center md:justify-end items-end">
              <div className="text-lg font-bold">
                <div className="text-white py-2">
                  HOTLINE: <a className="underline hover:font-semibold" href="tel:19892559036">989-255-9036</a>
                </div>
                <span className="font-normal">&copy;{moment(new Date()).format('YYYY')}</span> <a href="/">district28aa.org</a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}


Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
